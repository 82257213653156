import * as React from 'react';
import UserCard from "./UserCard/UserCard";
import {connect} from "react-redux";
import "./Header.css"
import HeaderUser from "./HeaderUser/HeaderUser";

interface IState {
    userCardIsOpen: boolean;
}

interface IProps {
    username: string;
}

class Header extends React.Component<IProps, IState> {

    render() {
        const {username} = this.props;
        return (
            <div>
                <nav className="navbar navbar-dark fixed-top p-0 header-main">
                    <div className="container-fluid">
                        <a className="navbar-brand"
                           href="/">G-builder</a>
                        <HeaderUser username={username}/>
                    </div>
                </nav>
            </div>
        )
    }
}

export default connect((state: any) => ({
    username: state.user.username
}), {})(Header);
